<template>
  <div>
    <spinner v-if="processing || !query" />
    <div v-else-if="!query.matches || query.matches.length == 0">
      <b-card >
        
        <h4 v-if="query.status == 'failed'">{{$t('identification.messages.failed')}}</h4>
        <h4 v-if="query.status == 'no-match'">{{$t('identification.messages.no-match')}}</h4>
        <h4 v-if="query.status == 'no-face'">{{$t('identification.messages.no-face')}}</h4>
      </b-card>
    </div>
    <div v-else-if="query.matches.length > 0">
      <b-card v-for="match in query.matches" :key="match.id">
        <query-match :match="match" />
      </b-card>
    </div>

      
    </div>
  </div>
</template>

<script>
import { show } from "@/api/queries.api";
import Spinner from "@/components/Spinner.vue";
import QueryMatch from "./QueryMatch.vue";
export default {
  name: "QueryMatches",

  components: { Spinner, QueryMatch },
  props: ["initialQuery"],
  data() {
    return {
      query: null,
    };
  },
  watch: {
    initialQuery() {
      this.query = this.initialQuery;
      this.refresh();
    },
  },
  computed: {
    processing() {
      return (
        this.query &&
        this.query.status &&
        ["pending", "processing"].includes(this.query.status)
      );
    },
  },
  methods: {
    refresh() {
      if (!this.processing || this.timer) return;
      this.timer = setInterval(async () => {
        const { data } = await show(this.query.id);
        if (!["pending", "processing"].includes(data.status)) {
          clearInterval(this.timer);
          this.query = data;
        }
      }, 1000);
    },
  },
  mounted() {
    this.query = this.initialQuery;
    this.refresh();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss">
.query-match {
  display: flex;
  flex-direction: row;
  img {
    width: 128px;
    height: 128px;
    object-fit: cover;
    overflow: clip;
    border-radius: 0.5rem;
  }

  .person-info {
    margin-left: 1.5rem;
  }
}
</style>
