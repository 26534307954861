<template>
  <div>
    <go-back :title="$t('identification.show.title')" />

    <b-row v-if="query">
      <b-col xs="12" sm="12" md="6">
        <b-card>
          <img
            :src="thumbnail()"
            style="aspect-ratio: 1/1; object-fit: cover; width: 100%"
          />
        </b-card>
      </b-col>
      <b-col xs="12" sm="12" md="6">
        <query-matches :initial-query="query" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { show } from "@/api/queries.api";
import Spinner from "@/components/Spinner.vue";
import QueryMatches from "./components/QueryMatches.vue";
import GoBack from "@/components/Common/GoBack.vue";
export default {
  name: "QueryShow",
  components: { Spinner, QueryMatches, GoBack },
  data() {
    return {
      loading: false,
      queryId: null,
      query: null,
      timer: null,
    };
  },
  watch: {
    $route(to, from) {
      this.queryId = to.params.id;
      this.fetch();
    },
  },
  computed: {
    processing() {
      return (
        this.query &&
        this.query.status &&
        ["pending", "processing"].includes(this.query.status)
      );
    },
  },
  methods: {
    async fetch() {
      if (this.loading) return;
      //TODO: catch
      this.loading = true;
      const { data } = await show(this.queryId);

      this.query = data;
      this.loading = false;
    },
    thumbnail() {
      return this.query?.photo?.media?.url;
    },
  },
  mounted() {
    this.queryId = this.$route.params.id;
    this.fetch();
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style>
img {
  max-width: 100%;
}
</style>